import React, { Fragment } from 'react';

import GlobalStyle from './src/components/GlobalStyle';

export const wrapPageElement = ({ element }) => (
    <Fragment>
        <GlobalStyle />
        {element}
    </Fragment>
);
