import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --clr-white: #fff;
        --clr-dark: #0B1521;

        --clr-grey-light: #EAF0F3;
        --clr-grey: #666;

        --clr-theme-1: #1DAAE2;
        --clr-theme-2: #B036AB;
        --clr-theme-3: #D7179D;

        --clr-text: var(--clr-dark);

        --clr-link: red;
        --clr-link-hover: blue;

        --ff-body: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        --ff-title: 'Josefin Sans', sans-serif;

        --fw-body: 400;
        --fw-bold: 600;

        --fs-body: 1.8em;

        --fs-xsml: 1.2rem;
        --fs-sml: 1.4rem;
        --fs-med: 1.8rem;
        --fs-lrg: 2.8rem;
        --fs-xlrg: 3.6rem;
        --fs-xxlrg: 4.8rem;
        --fs-3xlrg: 5.4rem;

        --lh-body: 1.5;
        --lh-title: 1.2;

        --container-width: 120rem;
        --container-thin-width: 96rem;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    html {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        font-size: 62.5%;
        height: 100%;
    }

    body {
        background-color: var(--clr-white);
        color: var(--clr-text);
        font-family: var(--ff-body);
        font-size: var(--fs-body);
        font-weight: var(--fw-body);
        height: 100%;
        line-height: var(--lh-body);
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-weight: var(--fw-body);
        margin-bottom: 0;
        margin-top: 0;
    }

    strong {
        font-weight: var(--fw-bold);
    }

    main {
        display: block;
    }

    blockquote {
        margin: 0;
        padding: 0;
    }

    figure {
        margin: 0;
    }

    img {
        display: block;
    }

    ul,
    ol,
    dl {
        list-style: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }

    dd {
        margin-left: 0;
    }

    a {
        color: var(--clr-link);
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: var(--clr-link-hover);
        }
    }

    hr {
        border: 0;
        border-top: .1rem var(--clr-grey) solid;
        margin: 3rem 0;
    }
`;

export default GlobalStyle;
